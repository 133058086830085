<template>
    <div ref="exportContentSummary">
        <!-- Simplified table -->
        <table
            style="width: 100%;"
            :id="index1 + 'table'"
        >
            <!-- headerTable -->
            <tr>
                <th 
                    v-for="(header, index) in simplifiedHeaderTable[0]" 
                    :key="index+ 'headerTable'"
                >
                    <div v-if="index === 0">
                    </div>
                    <div v-html="header"></div>
                </th>
            </tr>
            <!-- Data -->
            <tr 
                v-for="(row, rowIndex) in this.simplifiedSegmentationItemValues[index1]" 
                :key="rowIndex + 'tableData'"
            >
                <td 
                    v-for="(cell, cellIndex) in row" 
                    :key="cellIndex" 
                >
                    {{ formatNumber(cell) }}
                </td>
            </tr>

            <!-- Total -->
            <tr 
                v-for="(total, totalIndex) in this.simplifiedTotals[index1]" 
                :key="totalIndex"
            >
                <td 
                    v-for="(cell, cellIndex) in total" 
                    :key="cellIndex" 
                >
                    <div
                        v-if="cellIndex === 0" 
                        style=" text-align: right; font-weight: bold; "
                    >
                        {{ formatNumber(cell) }}
                    </div>
                    <div v-else 
                        style="font-weight: bold; "
                    >
                        {{ formatNumber(cell) }}
                    </div>
                </td>
            </tr>

        </table>
    </div>
</template>
<script>
export default {
    name:'ExportTableSingleSegmentationSummary',
    emits:['storeToVuexTablesForWord'],
    props:[
        'index1',
        'simplifiedSegmentationTitles',
        'simplifiedHeaderTable',
        'simplifiedSegmentationItemValues',
        'simplifiedTotals',
        'valueDisplayOption'
    ],
    components:{

    },
    data() {
        return {
        };
    },
    mounted(){
        // nenxTick execute after DOM render 
        this.addTablesForWord();
        // this.$nextTick(()=>{
            
        // })
    },
    methods:{
        addTablesForWord(){
            const exportContent = {
                content: this.$refs.exportContentSummary.innerHTML,
                name: 'summarySingleSegmentation' + 'table1',
                segmentation: this.simplifiedSegmentationTitles[this.index1],
                order: this.index1
            }
            this.$emit("storeToVuexTablesForWord", { exportContent, order: exportContent.order })
        },


        formatNumber(num){
            if(num){
                // split int and decimals of number
                let [int , decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return num
            }
        },
    }
}
</script>

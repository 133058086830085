<template>
    <div class="container-datas" style="font-size: 1rem; margin-top: 2rem;">
        <div v-if="!loadingPage">
            <!-- {{ simplifiedSegmentationTitles }}
            {{ simplifiedHeaderTable }}
            {{ simplifiedSegmentationItemValues }}

            {{simplifiedTotals}} -->

            <!-- // outputFor Figure1 Charts -->
            
            <!-- {{toFigure1ChartYears}}
            {{toFigure1SegmItems}} -->

            <!-- // Max Value -->
            <!-- {{maxValueFigure1}} -->
            <div 
                v-for="(count, countIndex) in simplifiedSegmentationItemValues" 
                :key="countIndex"
                style="
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%;
                "
            >
                <div 
                    style="
                        display: grid;
                        grid-template-columns: 1fr;
                        position: relative;
                        gap: 8rem;
                        margin-bottom: 8rem;
                        padding: 0rem 2.5rem 0rem 2.5rem;
                    "
                >
                    <div style="width: 100%; position: relative;">
                        <!-- <ExportTableSingleSegmentationSummary 
                            :index1="countIndex"
                            :segmentationTitles="this."
                        
                        /> -->

                        <div style="
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr 1fr ;
                            gap: 8rem;
                            margin-bottom: 5rem;
                            padding: 0rem 2.5rem 0rem 2.5rem;
                        ">
                            <!-- Table -->
                            <div style="
                                width: 100%; 
                            ">
                                <div style=" position: relative;">
                                    <ExportTableSingleSegmentationSummary 
                                        :index1="countIndex"
                                        :simplifiedSegmentationTitles="simplifiedSegmentationTitles"
                                        :simplifiedHeaderTable="simplifiedHeaderTable"
                                        :simplifiedSegmentationItemValues="simplifiedSegmentationItemValues"
                                        :simplifiedTotals="simplifiedTotals"
                                        :valueDisplayOption="valueDisplayOption"
                                        @storeToVuexTablesForWord="storeToVuexTablesForWord"
                                    />
                                </div>
                            </div>
                            <!-- chart 1 -->
                            <div style="width: 100%; height: 500px;">
                                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px;font-weight:bold;">
                                        {{topicName}} Market Summary by {{simplifiedSegmentationTitles[countIndex][0][0]}}
                                    </span>
                                    <span  style="color:#929292; font-size: 12px;">
                                        Market Size {{toFigure1ChartYears[0]}}, Market Size {{toFigure1ChartYears[1]}}, CAGR {{toFigure1ChartYears[0]}}-{{toFigure1ChartYears[1]}}
                                    </span>
                                </div>
                                <VueAnychartFigure1 
                                    :years="toFigure1ChartYears"
                                    :data="toFigure1SegmItems[countIndex]"
                                    :maxValue="this.convertValueDisplayOption(maxValueFigure1[countIndex])"
                                    :valueDisplayOption="valueDisplayOption"
                                    :topicName="topicName"
                                    :segmentationName="simplifiedSegmentationTitles[countIndex][0][0]"
                                    :segmentationItemsFullName="segmentationItemsFullName[countIndex]"
                                    @addChartsForExportWord="storeToVuexChartsForWord"
                                    :exportToWordSummary="true"
                                    :index1="countIndex"
                                />
                            </div>

                            <!-- chart 2 -->
                            <div style="width: 100%; height: 350px;">
                                <!-- <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px;font-weight:bold;">{{topicName}} Market Size by {{segmentationTitles[index1][0][0]}}</span>
                                    <span  style="color:#929292; font-size: 12px;">Market Size {{toFigure1ChartYears[0]}}-{{toFigure1ChartYears[1]}}</span>
                                </div> -->
                                <VueAnychartFigure2 
                                    :years="toFigure1ChartYears"
                                    :data="toFigure2ChartData[countIndex]" 
                                    :valueDisplayOption="valueDisplayOption"
                                    :topicName="topicName"
                                    :segmentationName="simplifiedSegmentationTitles[countIndex][0][0]"
                                    :segmentationItemsFullName="segmentationItemsFullName[countIndex]"
                                    @addChartsForExportWord="storeToVuexChartsForWord"
                                    :exportToWordSummary="true"
                                    :index1="countIndex"
                                />
                            </div>

                            <!-- chart3 SpiderChart -->
                            <div style="width: 100%; height: 350px;" >
                                <!-- <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px;font-weight:bold;">{{topicName}} Market CAGR by {{segmentationTitles[index1][0][0]}}</span>
                                    <span  style="color:#929292; font-size: 12px;">
                                        CAGR {{toFigure1ChartYears[0]}}-{{toFigure1ChartYears[1]}}
                                    </span>
                                </div> -->
                                
                                <VueAnychartFigureRadar
                                    :years="toFigure1ChartYears"
                                    :data="toFigureSpiderData[countIndex]"
                                    :topicName="topicName"
                                    :segmentationName="simplifiedSegmentationTitles[countIndex][0][0]"
                                    :segmentationItemsFullName="segmentationItemsFullName[countIndex]"
                                    @addChartsForExportWord="storeToVuexChartsForWord"
                                    :exportToWordSummary="true"
                                    :index1="countIndex"
                                    @finishLoading="finishLoading"
                                    :simplifiedSegmentationItemValuesLength="simplifiedSegmentationItemValues.length"
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
// Table
import ExportTableSingleSegmentationSummary from './ExportTableSingleSegmentationSummary.vue';
// Chart
import VueAnychartFigure1 from '../ForecastData/VueAnychartFigure1.vue';
import VueAnychartFigure2 from '../ForecastData/VueAnychartFigure2.vue';
import VueAnychartFigureRadar from '../ForecastData/VueAnychartFigureRadar.vue';

export default {
    name:'ExportForecastDataSummaryToWordSingle',
    components:{
        ExportTableSingleSegmentationSummary,
        VueAnychartFigure1,
        VueAnychartFigure2,
        VueAnychartFigureRadar,
    },
    emits:['endDataFinish'],
    props:[
        'output',
        'topicName',
        'valueDisplayOption',
        'decimalsOption',
    ],
    data(){
        return {
            loadingPage: true,

            // Simplified Table
            simplifiedSegmentationTitles: [],           //assignOutputSimplified
            simplifiedHeaderTable: [],                  //assignOutputSimplified
            simplifiedSegmentationItemValues: [],       //assignOutputSimplified

            // segmentationITemsFullName 
            segmentationItemsFullName : [],

            // Totals
            simplifiedTotals: [],

            // outputFor Figure1 Charts
            toFigure1ChartYears:[],
            toFigure1SegmItems:[],

            //output For figure2 charts
            toFigure2ChartData: [],
            //output For figure3 FigureSpider charts
            toFigureSpiderData: [],

            // Max Value
            maxValueFigure1: [], // for figure taken by datas from figure 1
            
        }
    },
    mounted(){
        this.assignOutputSimplified(); 
        this.assignOutputToFigure1Chart();
        this.getTotalRowSimplified(); 
        this.assignSegmItemFullNameOrDescription();

        this.assignOutputToFigure2Chart();
        this.assignOutputToFigureSpiderChart();
        // this.assignOutputToFigure3Chart();

        this.loadingPage = false;
    },
    methods:{
        finishLoading(){
            this.$emit("endDataFinish");
        },

        assignOutputSimplified(){
            // SHOW ARRAY ['US$M', FIRSTYEAR, LASTYEAR]
            let years = [''];
            let newYears = []; 
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => newYears.push(item.year))

            const firstElement = newYears[0]
            const lastElement = newYears[newYears.length - 1]
            
            years.push(firstElement);
            years.push(lastElement);

            // ADD TO YEARS [SUM, CARG] TO SHOW
            // SHOW ARRAY ['US$M', FIRSTYEAR, LASTYEAR, SUM, GAGR]

            const fyString = firstElement.toString();
            const lyString = lastElement.toString();

            let sumHeader = '∑'+fyString.slice(-2) +'-'+ lyString.slice(-2);
            years.push(sumHeader);

            // years.push('CAGR');
            let cagr = 'CAGR <br />'+fyString.slice(-2) +'-'+ lyString.slice(-2); 
            years.push(cagr);

            this.simplifiedHeaderTable.push(years);

            for (let index = 0; index < this.output.length; index++) {
                let titles = [];
                // this.simplifiedSegmentationTitles SHOW MAIN TITLE 
                const segmentationName = this.output[index].segmentations[0].name;
                titles.push([segmentationName]);
                this.simplifiedSegmentationTitles.push(titles)


                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.forEach(item => {
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem', 1,2,3,4,5,6,7]

                    // 1st Column segmSimplifiedTable
                    let segmItemName

                    if(item.description){
                        segmItemName = item.description
                    }
                    else{
                        if (item.regionId != null) {
                            segmItemName = item.region.name
                        }
                        else {
                            segmItemName = item.name
                        }
                    }

                    const versusValue = [segmItemName]

                    // item.documentProgramTopic.documentProgramValues
                    const dataValues = item.documentProgramTopic.documentProgramValues;

                    // FistYearData
                    const firstYearData = dataValues[0]
                    versusValue.push(this.tofixValueOptionDecimate(firstYearData.value))

                    const lastYearData = dataValues[dataValues.length - 1]
                    versusValue.push(this.tofixValueOptionDecimate(lastYearData.value))
                    
                    // set MaxValue
                    if(!this.maxValueFigure1[index] || firstYearData.value > this.maxValueFigure1[index]){
                        this.maxValueFigure1[index] = firstYearData.value
                    }
                    if (!this.maxValueFigure1[index] || lastYearData.value > this.maxValueFigure1[index]) {
                        this.maxValueFigure1[index] = lastYearData.value;
                    }
                    
                    // SUM
                    let sum = 0;
                    item.documentProgramTopic.documentProgramValues.forEach(item => {
                        let converted = this.tofixValueOptionDecimate(item.value) 
                        sum += parseFloat(converted)
                        // sum += this.tofixValueOptionDecimate(item.value)
                    })

                    versusValue.push(this.toFixDecimate3(sum));

                    // CAGR
                    let cagr
                    if(item.documentProgramTopic.cagr !== null){ // NULL VALIDATION TODO: ASK 
                        cagr = item.documentProgramTopic.cagr.toFixed(1) + '%'; 
                    } 
                    else{
                        cagr = 0 + '%';
                    }
                     
                    versusValue.push(cagr);
                    versusValueFinal.push(versusValue)
                })
                this.simplifiedSegmentationItemValues.push(versusValueFinal)
            }

        },

        assignOutputToFigure1Chart() {
            // To show for table Chart 
            // ['segm1',0,1]
            // ['segm1',0,1]
            // ['segm1',0,1]

            for( let index = 0; index< this.output.length; index++ ){
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // segmItemName
                    // ['segmentationItem'] 
                    let segmItemName
                    const versusValue = []

                    if(item.regionId != null){
                        if(item.name){
                            segmItemName = item.name
                        }
                        else{
                            segmItemName = item.region.name
                        }
                    }
                    else{
                        segmItemName = item.name
                    }

                    versusValue.push(segmItemName)

                    // DATA VALUES ['segmnItem', 1 , 2]
                    const dataValues = item.documentProgramTopic.documentProgramValues

                    // getMax Value
                    const firstYearData = dataValues[0]
                    versusValue.push( this.tofixValueOptionDecimate(firstYearData.value))

                    const lastYearData = dataValues[dataValues.length - 1]
                    versusValue.push( this.tofixValueOptionDecimate(lastYearData.value))

                    // Add CAGR Formula ['segmItem', 1, 2, ,CAGR FORMULA]
                    let cagrFormula
                    if(item.documentProgramTopic.cagr !== null){
                        cagrFormula = item.documentProgramTopic.cagr.toFixed(1) + '%';
                    }
                    else{
                        cagrFormula = 0 + '%';
                    }

                    versusValue.push(cagrFormula);
                    versusValueFinal.push(versusValue);
                })
                this.toFigure1SegmItems.push(versusValueFinal)
            }

            // to Show [2024,2025]

            let newYears = []
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => newYears.push(item.year))

            const firstElement = newYears[0]
            const lastElement = newYears[newYears.length - 1]

            this.toFigure1ChartYears.push(firstElement);
            this.toFigure1ChartYears.push(lastElement);
        },

        getTotalRowSimplified(){
            let segmentationItemValuesClone = JSON.parse(JSON.stringify(this.simplifiedSegmentationItemValues));

            segmentationItemValuesClone.map(segmItem => {
                let b = []
                segmItem.map(si=> {
                    if(b.length === 0){
                        let totArray = []
                        si.map((s, index) => {
                            if(index === 0){
                                totArray.push("Total")
                            }
                            else{
                                if(index !== si.length -1 ){
                                    if(typeof s !== "number"){
                                        let parseToNumber = Number(s);
                                        totArray.push(parseToNumber)
                                    }
                                    else{
                                        totArray.push(s)
                                    }
                                }
                            }

                        })
                        b.push(totArray)
                    }
                    else{
                        si.map((s, index2) => {
                            if(index2 !== 0 && index2 !== si.length -1 ){
                                if(typeof s !== "number"){
                                    let parseToNumber = Number(s);
                                    b[0][index2] += parseToNumber;
                                } 
                                else{
                                    b[0][index2] += s;
                                }
                            }
                        })
                    }
                })

                let c = [];

                const d = b[0].map((item, index) => {
                    if(index != 0){
                        return item = this.toFixDecimate3(item)
                    }
                    else{
                        return item
                    }
                })

                // add Cagr to totalrow 
                const cagrValue = this.calculateCAGRSimplified(d).toFixed(1) + '%';
                d.push(cagrValue);

                // add D Array to c 
                c.push(d);

                this.simplifiedTotals.push(c);
            })
        },
        assignSegmItemFullNameOrDescription(){
            for (let index = 0; index < this.output.length; index++) {
                let segmItemFullNameArray = [];
                this.output[index].segmentations[0].segmentationItems.map(
                    item => {
                        let segmItemName
                        if(item.description){
                            segmItemName = item.description
                        }
                        else{
                            if(item.regionId != null){
                                segmItemName = item.region.name
                            }
                            else{
                                segmItemName = item.name
                            }
                        }
                        segmItemFullNameArray.push(segmItemName)
                    }
                )
                this.segmentationItemsFullName.push(segmItemFullNameArray)
            }
        },

        assignOutputToFigure2Chart() {
            // to Show For Table Chart
            // ['segm1',10]
            // ['segm2',10]
            // ['segm3',10]

            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = [];

                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // versus ValueFinal Is the array that show title, and Sum 
                    // [segmItem , 19]
                    let segmItemName;

                    const versusValue = []
                    if (item.regionId != null) {
                        if(item.name){
                            segmItemName = item.name
                        }
                        else{
                            segmItemName = item.region.name
                        }
                    }
                    else {
                        segmItemName = item.name
                    }
                    versusValue.push(segmItemName)

                    let sum = 0;
                    item.documentProgramTopic.documentProgramValues.map(item => {
                        let converted = this.tofixValueOptionDecimate(item.value) 
                        sum += parseFloat(converted)
                    })

                    versusValue.push(this.toFixDecimate3(sum));

                    // versusValue.push( this.tofixValueOptionDecimate(sum))
                    versusValueFinal.push(versusValue)
                })
                this.toFigure2ChartData.push(versusValueFinal)
            }
        },

        assignOutputToFigureSpiderChart(){
            for(let index = 0; index < this.output.length; index++){
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(
                    item => {
                        let segmItemName
                        const versusValue = []

                        if (item.regionId != null) {
                            if(item.name){
                                segmItemName = item.name
                            }
                            else{
                                segmItemName = item.region.name
                            }
                        }
                        else {
                            segmItemName = item.name
                        }

                        versusValue.push(segmItemName)

                        let cagrFormula 
                        if(item.documentProgramTopic.cagr !== null){// NULL VALIDATION TODO: ASK 
                            cagrFormula = item.documentProgramTopic.cagr.toFixed(1); 
                        }
                        else{
                            cagrFormula = 0; 
                        }

                        versusValue.push(cagrFormula);
                        versusValueFinal.push(versusValue)
                })
                this.toFigureSpiderData.push(versusValueFinal)
            }
        },

        // EMITS
        storeToVuexTablesForWord(table){
            this.$store.dispatch('addExportOutputTables', table.exportContent);
        },
        storeToVuexChartsForWord( chart ){
            this.$store.dispatch('addExportOutputCharts', chart.exportContent);
        },

        // HELPERS
        tofixValueOptionDecimate(data){
            const valueDisplay = this.convertValueDisplayOption(data);
            return this.toFixDecimate3(valueDisplay);
        },
        // Convert Million, Billion && decimates 
        convertValueDisplayOption(data){
            if(this.valueDisplayOption === 'billions'){
                return data / 1000;
            }
            else{
                return data
            }
        },
        toFixDecimate3(cell) {
            if(this.decimalsOption === '0.'){
                if(Number.isInteger(cell)){
                    return cell
                }
                else{
                    return Math.round(cell);
                }
            }
            if(this.decimalsOption === '.0'){
                return this.roundTo(cell, 1).toFixed(1) 
            }
            if(this.decimalsOption === '.00'){
                return this.roundTo(cell, 2).toFixed(2)     
            }
            if(this.decimalsOption === '.000'){
                return this.roundTo(cell, 3).toFixed(3)     
            }
        },

        roundTo(num, decimals) {
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor)/factor;
        },

        calculateCAGR(values){
            let val = [...values]
            val.shift();
            val.pop();

            let initialValue = val[0];
            let finalValue = val[val.length -1];
            let n = val.length -1
            
            const cagr = Math.pow(finalValue / initialValue, 1/n) -1; 
            return (cagr) ? cagr * 100 : 0  
        },

        calculateCAGRSimplified(values){
            let val = [...values]
            val.shift();
            val.pop();

            let initialValue = val[0];
            let finalValue = val[val.length - 1];
            //  using toFigure1ChartYears for getting  years sub  
            let n =this.toFigure1ChartYears[1] - this.toFigure1ChartYears[0];

            const cagr = Math.pow(finalValue / initialValue , 1/n) -1; 
            return (cagr) ? cagr * 100 : 0
        },

        formatNumber(num){
            if(num){
                // split int and decimals of number
                let [int , decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return num
            }
        },
    },
    watch:{

    },
}
</script>

<style>
    
</style>
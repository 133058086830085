import { axiosProvider } from "../../../../../../../shared/config/axios.config";


async function exportToWord(formData, topicName, segmentationSelected, scenarioName) {
    await axiosProvider.post(`/conversion/convert`, formData, {
        responseType: 'blob'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${topicName}_By${segmentationSelected}_${scenarioName}.docx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error:', error);
            throw error;
            // alert('Hubo un error en el servidor. Por favor, intenta nuevamente o contacta al soporte.');
        });
}


async function exportSummaryToWord(formData, topicName,scenarioName) {
    await axiosProvider.post(`/conversion/exportSummary`, formData, {
        responseType: 'blob'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${topicName}_Summary_${scenarioName}.docx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error:', error);
            throw error;
            // alert('Hubo un error en el servidor. Por favor, intenta nuevamente o contacta al soporte.');
        });
}

async function exportToWordCompanyProfiles(formData, topicName){
    await axiosProvider.post(`/conversion/exportCompanyProfiles`, formData,{
        responseType:'blob'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${topicName}-companyProfile.docx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error:', error);
            throw error;
            // alert('Hubo un error en el servidor. Por favor, intenta nuevamente o contacta al soporte.');
        });

}

export { exportToWord,exportSummaryToWord, exportToWordCompanyProfiles }